import React from "react";
import { Link } from "gatsby";
import { Box, Heading, Text } from "grommet";

import Section from "@components/Section";

// markup
const NotFoundPage = () => {
  return (
    <Section constrained="hd">
      <Box pad={{ vertical: "xlarge" }} align="center">
        <Box width={{ max: "730px" }} align="center">
          <Heading
            level={1}
            size="large"
            className="secondary-font"
            margin={{ bottom: "medium" }}
            textAlign="center"
          >
            Something went wrong
          </Heading>

          <Heading
            level={2}
            size="large"
            className="secondary-font"
            margin={{ bottom: "xlarge" }}
            textAlign="center"
          >
            We're unable to find the page you're looking for{" "}
          </Heading>

          <Text>
            <Link to="/">Back home</Link>
          </Text>
        </Box>
      </Box>
    </Section>
  );
};

export default NotFoundPage;
